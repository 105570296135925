<template>
    <div>
        <template v-if="hasValidade">
            <div class="container-form">
                <form action="">
                    <div class="container-produto">
                        <div class="label label-produto">
                            <span class="produto"> Produto <span class="obrigatorio">*</span> </span>
                        </div>
                        <div class="produto-img">
                            <div class="open-img" @click="showImagem = true">
                                <img src="../assets/img/fullscreen.svg" alt="abrir imagem" />
                            </div>
                            <img
                                id="produtoImg"
                                :src="
                                    'https://147266017877-app-images.s3.sa-east-1.amazonaws.com/' +
                                    produto.codigo_de_barras +
                                    '.webp'
                                "
                                @error="replaceByDefault"
                                alt="produto 1"
                            />
                        </div>
                    </div>
                    <div class="container-codigo">
                        <div class="label label-codigo">
                            <span class="codigo"> Código de barras <span class="obrigatorio">*</span> </span>
                        </div>
                        <div class="input input-codigo">
                            <input type="number" autocorrect="off" v-model="codigo" disabled />
                        </div>
                    </div>
                    <div class="container-descricao">
                        <div class="label label-descricao">
                            <span class="descricao"> Descrição <span class="obrigatorio">*</span> </span>
                        </div>
                        <div class="input input-descricao">
                            <input type="text" v-model="descricao" disabled />
                        </div>
                    </div>
                    <div class="container">
                        <div class="label">
                            <span> Corredor <span class="obrigatorio">*</span> </span>
                        </div>
                        <div class="input">
                            <input
                                v-model="modulo"
                                type="text"
                                autocorrect="off"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                maxlength="50"
                                placeholder="Preencha o corredor"
                                id="inputModulo"
                                @input="validaModulo"
                            />
                        </div>
                        <div v-if="moduloIsInvalid.mensagem" class="mensagemErro">{{ moduloIsInvalid.mensagem }}</div>
                    </div>
                    <div class="containerQV">
                        <div class="container-validade">
                            <div class="label label-validade">
                                <span class="validade"> Data de validade <span class="obrigatorio">*</span> </span>
                            </div>
                            <div class="input input-validade">
                                <input type="text" v-model="dataValidade" disabled />
                            </div>
                        </div>
                        <div class="container-quantidade">
                            <div class="label label-quantidade">
                                <span class="quantidade"> Quantidade <span class="obrigatorio">*</span> </span>
                                <tooltip class="tooltip-qnt" :isLeft="true" :msg="msgQnt"></tooltip>
                            </div>
                            <div class="input input-quantidade">
                                <input
                                    type="number"
                                    min="0"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    max="999999"
                                    maxlength="6"
                                    placeholder="000000"
                                    v-model="quantidade"
                                    required
                                    @input="validaQuantidade"
                                />
                                <div class="controle">
                                    <button id="btn-minus" type="button" @click="decrementQuantidade">
                                        <img src="../assets/img/minus.svg" alt="subtrair" />
                                    </button>
                                    <button id="btn-plus" type="button" @click="incrementQuantidade">
                                        <img src="../assets/img/plus.svg" alt="adicionar" />
                                    </button>
                                </div>
                            </div>
                            <div v-if="quantidadeIsInvalid" class="mensagemErro">Quantidade inválida</div>
                        </div>
                    </div>
                    <div v-if="showEstoque" class="mensagemErro2">
                        Atenção: O estoque da loja é maior do que você informou. Certifique-se que conferiu as validades
                        corretamente!
                    </div>
                    <div class="container-validade-loja">
                        <div class="label label-validade-loja">
                            <span class="validade-loja"> Validades na loja </span>
                        </div>
                        <div class="input input-validade-loja">
                            <textarea v-model="validades" disabled class="input-textarea"></textarea>
                        </div>
                    </div>
                    <div class="container-inclusao">
                        <div class="label label-inclusao">
                            <span class="inclusao"> Deseja fazer uma inclusão desse produto? </span>
                        </div>
                        <div class="container-btnInclusao">
                            <button @click="enviaForm(false)" type="button">Não</button>
                            <button @click="enviaForm(true)" type="button">Sim</button>
                        </div>
                    </div>
                </form>
            </div>

            <footer-save @editaInclusao="editaInclusao" @envia-form="enviaForm(false)"></footer-save>

            <transition name="fade">
                <div class="tela-over" v-if="showImagem">
                    <div class="tela-imagem">
                        <div class="close-imagem" @click="showImagem = false">
                            <img src="../assets/img/close-black.svg" alt="fechar a imagem" />
                        </div>
                        <img
                            id="imgFull"
                            :src="
                                'https://147266017877-app-images.s3.sa-east-1.amazonaws.com/' +
                                produto.codigo_de_barras +
                                '.webp'
                            "
                            @error="replaceByDefault"
                            alt="produto 1"
                        />
                    </div>
                </div>
            </transition>

            <transition name="fade">
                <div v-if="showConfirm" class="popUp">
                    <div class="tela-mensagem">
                        <div class="cancel-msg">
                            Descartar alterações?
                            <div class="close-cancel" @click="showConfirm = false">
                                <img src="../assets/img/close-black.svg" alt="fechar a modal" />
                            </div>
                        </div>
                        <h3>Todas as alterações serão perdidas</h3>
                        <div class="cancel-btn">
                            <button @click="showConfirm = false" type="button">Não</button>
                            <button @click="voltaPagina" type="button">Descartar</button>
                        </div>
                    </div>
                </div>
            </transition>

            <msg-sucess v-if="showSucess" :mensagemSucess="msgS"></msg-sucess>
            <msg-error v-if="showAlert" @closeError="closeError" :mensagemError="msgE"></msg-error>
        </template>
        <template v-else>
            <div class="popUp">
                <div class="tela-mensagem">
                    <div class="sucess">
                        <img src="../assets/img/alert-white.svg" alt="imagem de check" />
                    </div>
                    <div class="sucess-msg">
                        Nessa tarefa você deverá fazer a inclusão de todas as validades existentes do produto.
                        <div class="sucess-buttons">
                            <button class="btn-sucess" @click="enviaFormSemValidade()">Avançar</button>
                            <button class="btn-sucess" @click="voltaPagina()">Voltar</button>
                            <button class="btn-sucess" @click="incluiSemValidade()">Quantidade Zero</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import FooterSave from '../components/FooterSave';
import Tooltip from '../components/Tooltip';
import HTTP from '../http/http.js';
import MsgSucess from '../components/MsgSucess';
import MsgError from '../components/MsgError';
import img from '../assets/img/broken-image.svg';
import amplitude from 'amplitude-js';

export default {
    components: {
        FooterSave,
        Tooltip,
        MsgSucess,
        MsgError,
    },

    data() {
        return {
            codigo: '',
            descricao: '',
            validades: '',
            quantidade: '',
            modulo: '',
            moduloFinal: '',
            quantidadeIsInvalid: false,
            produto: null,
            showImagem: false,
            showValidade: false,
            showConfirm: false,
            showSucess: false,
            showAlert: false,
            mensagem: {},
            msgQnt: 'Quantidade existente do produto na loja com a data de validade indicada.',
            msgS: 'Tarefa concluida com sucesso',
            msgE: 'A tarefa não pode ser concluida, tente novamente. Se o erro persistir, contate seu supervisor.',
            hasValidade: true,
            moduloIsInvalid: {
                mensagem: '',
                valida: false,
            },
            showEstoque: false,
        };
    },

    computed: {
        getTarefa() {
            return this.$store.getters.getTarefa;
        },
        getTarefas() {
            return this.$store.getters.getTarefas;
        },
    },

    created() {
        let codigo = this.$route.params.codigo;
        let validade = this.$route.params.validade;

        this.$store.dispatch('setCodigo', codigo);
        this.$store.dispatch('setValidade', validade);

        this.produto = this.getTarefa;
        this.modulo = this.checkModulo(this.produto.modulo);

        if (!this.produto.data_validade) {
            this.hasValidade = false;
        } else {
            this.descricao = this.produto.descricao;
            this.dataValidade = this.convertDate(this.produto.data_validade);
            let validadesString = this.produto.validades_existentes;
            let validadesArray = validadesString.split(',');
            let validadesAA = [];
            validadesArray.forEach((item) => {
                let retirarAno = item.slice(6, 8);
                let replaceAno = item.replace(`/${retirarAno}`, '/');
                validadesAA.push(replaceAno);
            });
            this.validades = validadesAA.join(', ');
        }
        this.codigo = this.produto.codigo_de_barras;

        amplitude.getInstance().init('f5b8a735db8cb839a719abb3cbfe1e85');
        let event = 'Tela Tarefa';
        amplitude.getInstance().logEvent(event);
    },

    methods: {
        incluirProduto() {
            this.$store.dispatch('setCodigoInclusao', this.codigo);
            this.$store.dispatch('setModuloInclusao', this.modulo);
            this.$router.push({ name: 'incluirProdutos' });
        },

        enviaForm(vaiAdicionarProduto) {
            amplitude.getInstance().init('f5b8a735db8cb839a719abb3cbfe1e85');
            let event = 'Salvar Tarefas';
            amplitude.getInstance().logEvent(event);

            if (!this.validaForm()) return;

            let codigo = this.$route.params.codigo;
            let validade = this.$route.params.validade;
            let usuario = sessionStorage.getItem('usuario');

            let data = {
                codigo_de_barras: this.codigo,
                quantidade: this.quantidade,
                validade: validade,
                modulo: this.moduloFinal,
                usuario: usuario,
            };

            if (navigator.onLine) {
                let loja = sessionStorage.getItem('loja');

                HTTP.patch(`/condor/${loja}/tarefas`, data)
                    .then(() => {
                        this.showSucess = true;
                        const itemToBeRemoved = { codigo, validade };

                        let todasTarefas = this.getTarefas;

                        todasTarefas.splice(
                            todasTarefas.findIndex(
                                (a) =>
                                    a.codigo_de_barras == itemToBeRemoved.codigo &&
                                    a.data_validade == itemToBeRemoved.validade
                            ),
                            1
                        );
                        this.isSending = false;

                        if (vaiAdicionarProduto) {
                            amplitude.getInstance().init('f5b8a735db8cb839a719abb3cbfe1e85');
                            let event = 'Botão Incluir Validade';
                            amplitude.getInstance().logEvent(event);
                            setTimeout(() => {
                                this.showSucess = false;
                                this.incluirProduto();
                            }, 3500);
                        } else {
                            setTimeout(() => {
                                this.showSucess = false;
                                this.isSending = false;
                                this.voltaPagina();
                            }, 1500);
                        }
                    })
                    .catch(() => {
                        this.showAlert = true;
                        this.isSending = false;
                        console.error('erro');
                        setTimeout(() => {
                            this.showAlert = false;
                            this.isSending = false;
                            this.voltaPagina();
                        }, 1500);
                    });
            } else {
                let produtosSalvos = JSON.parse(localStorage.getItem('alertasValidade'));

                let dataToLS = null;

                const itemToBeRemoved = { codigo, validade };

                let todasTarefas = this.getTarefas;

                todasTarefas.splice(
                    todasTarefas.findIndex(
                        (a) =>
                            a.codigo_de_barras == itemToBeRemoved.codigo && a.data_validade == itemToBeRemoved.validade
                    ),
                    1
                );

                if (produtosSalvos) {
                    produtosSalvos.push(data);

                    dataToLS = JSON.stringify(produtosSalvos);
                } else {
                    dataToLS = JSON.stringify([data]);
                }

                this.showSucess = true;

                localStorage.setItem('alertasValidade', dataToLS);

                this.$store.dispatch('plusNumeroSync');

                if (vaiAdicionarProduto) {
                    amplitude.getInstance().init('f5b8a735db8cb839a719abb3cbfe1e85');
                    let event = 'Botão Incluir Validade';
                    amplitude.getInstance().logEvent(event);
                    setTimeout(() => {
                        this.showSucess = false;
                        this.incluirProduto();
                    }, 3500);
                } else {
                    setTimeout(() => {
                        this.showSucess = false;
                        this.isSending = false;
                        this.voltaPagina();
                    }, 1500);
                }
            }
        },

        enviaFormSemValidade() {
            setTimeout(() => {
                this.showSucess = false;
                this.$store.dispatch('setTipoAlerta', 5);
                this.incluirProduto();
            }, 2000);
        },

        convertDate(date) {
            let dateSplit = date.split('-');
            let newDate = dateSplit[2] + '/' + dateSplit[1] + '/' + dateSplit[0];
            return newDate;
        },

        incluiSemValidade() {
            let usuario = sessionStorage.getItem('usuario');
            let data = {
                codigo_de_barras: this.codigo,
                prevencao: true,
                usuario: usuario,
            };

            let loja = sessionStorage.getItem('loja');

            if (navigator.onLine) {
                HTTP.patch(`/condor/${loja}/tarefas`, data)

                    .then(() => {
                        let codigo = this.codigo;

                        const itemToBeRemoved = { codigo, validade: '' };

                        let todasTarefas = this.getTarefas;

                        todasTarefas.splice(
                            todasTarefas.findIndex(
                                (a) =>
                                    a.codigo_de_barras == itemToBeRemoved.codigo &&
                                    a.data_validade == itemToBeRemoved.validade
                            ),
                            1
                        );

                        this.showSucess = true;

                        setTimeout(() => {
                            this.showSucess = false;
                            this.voltaPagina();
                        }, 1500);
                    })
                    .catch(() => {
                        this.showAlert = true;
                        console.error('erro');
                    });
            } else {
                let produtosSalvos = JSON.parse(localStorage.getItem('alertasValidade'));

                let dataToLS = null;

                let codigo = this.codigo;

                const itemToBeRemoved = { codigo, validade: '' };

                let todasTarefas = this.getTarefas;

                todasTarefas.splice(
                    todasTarefas.findIndex(
                        (a) =>
                            a.codigo_de_barras == itemToBeRemoved.codigo && a.data_validade == itemToBeRemoved.validade
                    ),
                    1
                );

                if (produtosSalvos) {
                    produtosSalvos.push(data);

                    dataToLS = JSON.stringify(produtosSalvos);
                } else {
                    dataToLS = JSON.stringify([data]);
                }

                this.showSucess = true;
                this.msgS = 'A tarefa será enviada assim que o App for sincronizado (com internet).';

                localStorage.setItem('alertasValidade', dataToLS);

                this.$store.dispatch('plusNumeroSync');

                setTimeout(() => {
                    this.showSucess = false;
                    this.voltaPagina();
                }, 1500);
            }
        },

        closeError() {
            this.showAlert = false;
        },

        validaQuantidade() {
            if (this.quantidade == '') {
                this.quantidade = 0;
            }

            if (
                this.quantidade < this.produto.estoque / 2 &&
                this.produto.estoque > 20 &&
                this.produto.estoque != null
            ) {
                this.showEstoque = true;
            } else {
                this.showEstoque = false;
            }

            this.quantidadeIsInvalid = this.quantidade < 0 ? true : false;
        },

        validaModulo() {
            var input = document.querySelector('#inputModulo');
            this.moduloIsInvalid.valida = this.modulo == '' || this.modulo > 100 ? true : false;

            if (this.moduloIsInvalid.valida) {
                this.moduloIsInvalid.mensagem = 'Informação obrigatória';
            } else {
                this.moduloIsInvalid.mensagem = '';
            }

            if (this.modulo.toString().length > input.maxLength) {
                this.modulo = parseFloat(this.modulo.toString().slice(0, input.maxLength));
            }

            // input.addEventListener('keypress', function (e) {
            //     if (!checkChar(e)) {
            //         e.preventDefault();
            //     }
            // });
            // function checkChar(e) {
            //     var char = String.fromCharCode(e.keyCode);

            //     var pattern = '[0-9]';
            //     if (char.match(pattern)) {
            //         return true;
            //     }
            // }
        },

        validaForm() {
            this.validaQuantidade();

            if (this.modulo == '' || this.modulo == 0) {
                this.moduloIsInvalid = {
                    mensagem: 'O corredor deve ser preenchido',
                    valida: true,
                };
            } else {
                this.moduloFinal = this.modulo;
                this.moduloIsInvalid = {
                    mensagem: '',
                    valida: false,
                };
            }

            if (this.quantidadeIsInvalid == true || this.moduloIsInvalid.valida == true) {
                return false;
            }

            return true;
        },

        decrementQuantidade() {
            this.quantidade--;
            this.validaQuantidade();
            if (this.quantidade < 0) {
                this.quantidade = 0;
            }
        },

        incrementQuantidade() {
            amplitude.getInstance().init('f5b8a735db8cb839a719abb3cbfe1e85');
            let event = 'Botão Adicionar Quantidade';
            amplitude.getInstance().logEvent(event);
            this.quantidade++;
            this.validaQuantidade();
            if (this.quantidade > 99999) {
                this.quantidade = 99999;
            }
        },

        editaInclusao() {
            amplitude.getInstance().init('f5b8a735db8cb839a719abb3cbfe1e85');
            let event = 'Cancelar Tarefas';
            amplitude.getInstance().logEvent(event);
            this.showConfirm = true;
        },

        checkModulo(target) {
            if (target == null || target == '') {
                return 0;
            } else {
                return target.replace('CORREDOR ', '');
            }
        },

        voltaPagina() {
            this.$router.go(-1);
        },

        replaceByDefault(e) {
            e.target.src = img;
        },
    },
};
</script>

<style scoped>
.container-form {
    padding: 15px;
    margin-top: 56px;
    margin-bottom: 66px;
    overflow-x: hidden;
}

.containerQV {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
}

.adc-validade,
.menos-validade {
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid #000;
    padding: 5px;
    border-radius: 20px;
    background-color: #f4f4f4;
    cursor: pointer;
    transition: all 0.2s;
}

.adc-validade:hover,
.menos-validade:hover {
    transition: all 0.2s;
    border-radius: 4px;
    background-color: #fff;
}

.adc-validade:hover h3,
.menos-validade:hover h3 {
    color: var(--verde);
}

.nova-validade {
    display: flex;
    align-items: center;
    margin-top: -5px;
    margin-bottom: 15px;
    justify-content: space-between;
}

.nova-validade2 {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: -5px;
    margin-bottom: 15px;
    justify-content: space-between;
    flex-direction: column;
}

.nova-validade h3,
.nova-validade2 h3 {
    color: var(--cinza-escuro);
    font-size: 14px;
}

.nova-validade h3 small,
.nova-validade2 h3 small {
    font-size: 11px;
}

.label span {
    font-size: 0.83rem;
}

.obrigatorio {
    color: var(--vermelho);
}

form div div span {
    color: var(--cinza-escuro);
}

.produto-img {
    display: flex;
    margin-top: 9px;
    margin-bottom: 15px;
    border: 1px solid #bdc1c6;
    padding: 5px;
    place-content: center;
}

#produtoImg {
    max-width: 60%;
    max-height: 35vh;
    min-height: 80px;
    margin: auto;
}

input {
    border: 1px solid var(--cinza);
    border-radius: 4px;
    font-size: 0.85rem !important;
    padding: 12px;
    width: 100%;
    text-overflow: ellipsis;
    outline-color: var(--vermelho);
}

input:disabled {
    background-color: #d2d2d2;
}

input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.controle {
    display: flex;
    left: 0;
    right: 0;
    justify-content: space-between;
    align-items: center;
}

.controle button {
    padding: 0rem 0.55rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: none;
}

#btn-plus {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
}

#btn-minus {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
}

.input-codigo,
.input-quantidade {
    display: flex;
    align-items: center;
    position: relative;
}

.input-quantidade input {
    text-align: center;
    padding: 12px 8px;
}

.input {
    margin-top: 5px;
    margin-bottom: 10px;
    align-items: center;
}

.mensagemErro {
    margin-top: -10px;
    margin-bottom: 15px;
    font-size: 0.8rem;
    color: var(--vermelho);
}

.mensagemErro2 {
    margin-top: -10px;
    margin-bottom: 15px;
    font-size: 0.8rem;
    color: var(--verde);
}

.tela-over {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-imagem {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: 100px 25px;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#imgFull {
    max-width: 250px;
    max-height: 250px;
    padding: 20px;
}

.open-img {
    position: absolute;
    display: flex;
    right: 21px;
    cursor: pointer;
    box-shadow: 0 0 0 2px #cdcdcd;
    background-color: #ececec;
    transition: all 0.2s;
    border-radius: 50%;
    padding: 10px;
}

.open-img:hover {
    border-radius: 5px;
    transition: all 0.2s;
}

.open-img img {
    width: 36px;
}

.close-imagem {
    position: absolute;
    top: -21px;
    right: -21px;
    box-shadow: 0 0 0 2px #cdcdcd;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #ececec;
    transition: all 0.2s;
}

.close-imagem:hover {
    border-radius: 5px;
    transition: all 0.2s;
}

.containerRemove {
    position: relative;
}

.label-float {
    position: relative;
    padding-top: 13px;
}

.label-float input {
    border: 1px solid var(--cinza);
    border-radius: 4px;
    min-width: 180px;
    font-size: 16px;
    transition: all 0.1s ease-out;
    -webkit-transition: all 0.1s ease-out;
    -moz-transition: all 0.1s ease-out;
    -webkit-appearance: none;
}

.label-float input::placeholder {
    color: transparent;
}

.label-float label {
    pointer-events: none;
    position: absolute;
    top: 10px;
    left: 20px;
    margin-top: 13px;
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
}

.label-float input:required:invalid + label {
    color: var(--cinza);
}

.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
    font-size: 13px;
    margin-top: -5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #fff;
    color: var(--vermelho);
}

.container-save {
    width: 100%;
    border-top: 1px solid var(--cinza);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px;
    display: flex;
    justify-content: space-between;
}

.container-btnInclusao {
    width: 90vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10%;
}

.container-btnInclusao button {
    margin-top: 5px;
    padding: 5px 15px;
    background-color: #fff;
    border: 1px solid var(--cinza);
    border-radius: 5px;
    cursor: pointer;
}

.container-btnInclusao button:hover {
    position: relative;
    top: 5px;
    background-color: var(--vermelho);
    color: #fff;
    border: 1px solid #dbdbdb;
}

.input-textarea {
    resize: none;
    width: 100%;
    padding: 8px;
    height: 35px;
    border-radius: 4px;
    font-size: 0.85rem;
    background-color: #f4f4f4;
}

.btn-scan {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 10px;
    background-color: transparent;
    display: flex;
    border: none;
    align-items: center;
}

.label {
    display: flex;
    align-items: center;
}

.popUp {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem {
    position: absolute;
    left: 0;
    top: 12%;
    right: 0;
    margin: 18px;
    background-color: #fff;
    border-radius: 5px;
    padding-bottom: 10px;
}

.tela-mensagem h3 {
    padding: 5px 15px;
    font-size: 16px;
}

.cancel-msg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 23px;
    font-weight: bold;
}

.close-cancel {
    display: flex;
    background-color: #d3d3d3;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
}

.close-cancel:hover {
    border-radius: 50%;
    transition: all 0.2s;
    background-color: #f4f4f4;
    border: 1px solid #d3d3d3;
}

.cancel-btn {
    margin-top: 8%;
    padding: 10px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.cancel-btn button {
    padding: 7px 15px;
    font-weight: bold;
    background-color: #f4f4f4;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-btn button:hover {
    position: relative;
    top: 5px;
    background-color: var(--vermelho);
    color: #fff;
}

.sucess {
    background-color: var(--vermelho);
    padding: 10px 20px;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.sucess img {
    width: 70px;
}

.sucess-msg {
    padding: 10px;
    height: 50%;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    text-align: center;
}

.sucess-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.btn-sucess {
    padding: 5px;
    border: 1px solid var(--vermelho);
    border-radius: 10px;
    font-size: 14px;
    background-color: #fff;
    color: var(--vermelho);
    cursor: pointer;
}

.btn-sucess:hover {
    background-color: var(--vermelho);
    color: #fff;
    position: relative;
    top: 5px;
}

@media (max-width: 319px) {
    .open-img img {
        width: 22px;
    }

    #produtoImg {
        max-width: 45%;
    }

    .containerQV {
        display: flex;
        flex-direction: column;
    }

    .cancel-msg {
        font-size: 16px !important;
    }

    .tela-mensagem h3 {
        text-align: center;
    }

    .tooltip-qnt {
        margin-left: 80px;
    }
}

@media (max-width: 359px) {
    .label span {
        font-size: 14px;
    }

    input {
        font-size: 15px;
    }

    .nova-validade h3 {
        font-size: 13px;
    }

    .tela-mensagem h3 {
        font-size: 15px;
    }

    .cancel-msg {
        font-size: 18px;
    }
}
</style>
